<template>
  <div>
    <app-cells position="start">
      <router-link  :to="{ name: 'experts-create' }" class="btn btn--link btn--link-icon">
        <span>Добавить эксперта</span>
      </router-link>
    </app-cells>
    <app-table-head
      @update-limiter="onUpdateLimiter"
      :count="table.items.length"
      :page="pagination.page"
      :all="pagination.count"
    />
    <app-cells position="start">
      <v-select
        @search="onFilterSearch"
        noDrop
        label="id"
        placeholder="Поиск по ФИО или ID"
        class="select select--minw"
      />
    </app-cells>
    <v-client-table
      @row-click="onRowClick"
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="tableDefault"
      class="table-default table-default--dynamic"
    >
      <div slot="last_name" slot-scope="props" class="table-default__left">
        {{ props.row.last_name }} {{ props.row.first_name }} {{ props.row.patronymic ? props.row.patronymic : '' }}
      </div>
      <template #directions="props">
        <span
          v-for="(item, index) of props.row.directions"
          :key="index"
        >
          {{ item }}<br>
        </span>
      </template>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="onClickPagination"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    />
  </div>
</template>

<script>
import {
  getExpertsList
} from '@/http'
import { debounce } from 'lodash'

export default {
  name: 'ExpertsList',
  data() {
    return {
      table: {
        items: [],
        columns: ['pk', 'last_name', 'directions'],
        options: {
          headings: {
            pk: 'ID',
            last_name: 'ФИО',
            directions: 'Направления'
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Вы еще не добавили ни одного эксперта',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          // rowClassCallback: row => `table-default__row table-default__row--${row.status.code}`,
          rowClassCallback: row => 'table-default__row',
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
      },
      table_limit: 20,
    }
  },
  created() {
    this.fetchItems()
  },
  methods: {
    fetchItems(page = this.pagination.page, page_size = this.table_limit, search = '') {
      getExpertsList(page, page_size, search)
        .then(response => {
          this.table.items = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
        })
    },
    onUpdateLimiter(data) {
      this.table_limit = data
      this.pagination.page = 1
      this.$refs.tableDefault.setLimit(this.table_limit)
      this.fetchItems()
    },
    onClickPagination(page) {
      this.pagination.page = page
      this.fetchItems()
    },
    onFilterSearch(search, loading) {
      loading(true)
      this.searchFilter(loading, search, this)
    },
    searchFilter: debounce((loading, search, vm) => {
      vm.fetchItems(1, vm.table_limit, search)
      loading(false)
    }, 350),
    onRowClick(row) {
      // const routeData = this.$router.resolve({name: 'directorate-detail', params: {id: row.row.pk}})
      // window.open(routeData.href, '_blank')
      this.$router.push({ name: 'experts-detail', params: { id: row.row.pk } })
    },
  }
}
</script>

